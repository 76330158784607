
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function Contact() {
    return (
        <div style={containerStyle}>
            <h2 style={titleStyle}>BİLGİLERİMİZ</h2>
            <div style={infoRow}>
                <span style={labelStyle}><strong>Firma Adı:</strong></span>
                <span style={valueStyle}>FLOW KONTROL SİSTEMLERİ TEKNİK HİZMETLERİ SANAYİ TİCARET LİMİTED ŞİRKETİ</span>
            </div>
            <div style={infoRow}>
                <span style={labelStyle}><strong>Adres:</strong></span>
                <span style={valueStyle}>Emek Mahallesi Okay Sokak NO:7/A Darıca Kocaeli</span>
            </div>
            <div style={infoRow}>
                <span style={labelStyle}><strong>Telefon:</strong></span>
                <span style={valueStyle}>+90 (551) 555 44 78 </span>
            </div>
            <div style={infoRow}>
                <span style={labelStyle}><strong>E-posta:</strong></span>
                <span style={valueStyle}>info@fkspump.com</span>
            </div>

            {/* Harita Bileşeni */}
            <div style={mapContainerStyle}>
                <MapContainer 
                    center={[40.795271, 29.362247]} // Darıca, Kocaeli Koordinatları
                    zoom={16}
                    style={{ height: '300px', width: '100%', borderRadius: '8px' }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[40.775631, 29.378585]} icon={customIcon}>
                        <Popup>Flow Kontrol Sistemleri</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
}

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    maxWidth: '500px',
    margin: '0 auto',
};

const titleStyle = {
    fontSize: '2rem',
    color: '#007bff',
    marginBottom: '20px',
    textAlign: 'center',
};

const infoRow = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
};

const labelStyle = {
    fontWeight: 'bold',
    color: '#333',
    flex: '1',
    textAlign: 'left',
};

const valueStyle = {
    color: '#555',
    flex: '2',
    textAlign: 'right',
    fontSize:"12px"
};

const mapContainerStyle = {
    marginTop: '20px',
    width: '100%',
};

// Özel bir icon tanımlama (isteğe bağlı)
const customIcon = new L.Icon({
    iconUrl: 'https://example.com/your-icon.png',
    iconSize: [32, 32],
});

export default Contact;