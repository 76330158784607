import React, { Component } from 'react';
import { Button, Card, CardText, CardTitle, CardImg, CardBody } from 'reactstrap';
import img6 from "../images/img6.jpg"
import img7 from "../images/img7.jpg"
import img8 from "../images/img8.jpg"
const items = [
    {
        src: img6,
        altText: `Image 6`,
        caption: `Image 6`,
        title: "BAKIM/ONARIM",
        description: "Firmamızın bakım ve onarım süreçlerindeki başarısının arkasında, eğitimli personel, kaliteli araç gereçler ve orijinal yedek parça kullanımına verdiğimiz önem bulunmaktadır. Bu üç unsur, yüksek performans ve güvenilirlik sağlamak için kritik rol oynamaktadır."
    },
    {
        src: img7,
        altText: `Image 7`,
        caption: `Image 7`,
        title: "SÜPERVİZYON",
        description: "Süpervizörlük hizmetimiz, ürünlerin uygulama alanlarına yerleştirilmesi, diğer tesisat ekipmanlarıyla entegrasyonu ve uyumu için danışmanlık sağlamayı kapsamaktadır. Bu süreç, projelerin verimli ve uyumlu bir şekilde gerçekleştirilmesini garanti eder. "
            },
    {
        src: img8,
        altText: `Image 8`,
        caption: `Image 8`,
        title: "PERİYODİK BAKIM ANLAŞMASI",
        description: "Pompa ve proje bazlı periyodik bakım hizmetimiz, işletme giderlerinizi düşürür, pompaların performansını yüksek tutarak büyük arızaların önüne geçer. Bakım anlaşması yapılan müşterilere ise 6 saat içinde müdahale, 7/24 hizmet ve yedek parça temini gibi ayrıcalıklar sunulur."
    }]
const cards = items.map(item => (
    <div className="col-lg-3 col-md-4 col-sm-6" style={{marginBottom:"8px"}}>
        <Card>
            <CardImg
                alt="Card image cap"
                src={item.src}
                top
                width="100%"
            />
            <CardBody className="card-body" style={{ height: "200px;", overflow: "hidden" }}>
                <CardTitle tag="h5" style={{fontSize:"16px", textAlign:"center"}}>
                    {item.title}
                </CardTitle>
                <CardText>
                    {item.description}
                </CardText>
            </CardBody>
        </Card>
    </div>
))
class ProductsComponent extends Component {

    render() {
        return (
            <div className="row">
                {cards}
            </div>
            
        );
    }
}

export default ProductsComponent;
