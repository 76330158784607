import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navi from "./Components/Navi";
import CoreuselComponent from "./Components/CoreuselComponent";
import Footer from "./Components/FooterComponent";
import AboutComponent from "./Components/AboutComponent";
import ProductsComponent from "./Components/ProductsComponent";
import { Container } from 'reactstrap';
import Contact from './Components/Contact';
function App() {
  return (
    <div className="App" style={{ backgroundColor: '#e6f0ff', minHeight: '100vh' }}>
      <Router>
        <Navi />
        <br />
        <br />
        <Container>
        <Routes>
          <Route path="/" element={<CoreuselComponent />} />
          <Route path="/about" element={<AboutComponent />} />
          <Route path="/products" element={<ProductsComponent />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        </Container>
        <br />
        <br />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
