import React, { Component } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../CSS/Footer.css"
class Footer extends Component {
    render() {
        const currentYear = new Date().getFullYear();
        return (
            <div>

                <footer className="py-3 my-4">
                    <ul className="nav justify-content-center pb-3 mb-3" style={{ borderBottom: "5px solid #0e0546" }}>
                        <li className="nav-item">
                            {/* <a href="mailto:servis@fkspump.com" target="_blank" rel="noopener noreferrer" className="icon nav-link px-2 text-body-primary" style={{ color: "red" }}>
                                <i className="fa fa-envelope icon-size"></i>
                            </a> */}
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=servis@fkspump.com" target="_blank" rel="noopener noreferrer" className="icon nav-link px-2 text-body-primary" style={{ color: "red" }}>
                            <i className="fa fa-envelope icon-size"></i>    
                        </a>

                        </li>

                        <li className="nav-item">
                            <a href="https://www.linkedin.com/company/fkspump/about/" target="_blank" rel="noopener noreferrer" className= "icon nav-link px-2 text-body-primary">
                                <i className="fab fa-linkedin icon-size"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://wa.me/905515554478" target="_blank" rel="noopener noreferrer" className="icon nav-link px-2 text-body">
                                <i className="fab fa-whatsapp icon-size" aria-hidden="true" style={{color : "green"}}></i>
                            </a>
                        </li>

                    </ul>
                    <p className="text-center text-body-secondary">
                        © {currentYear} Designed By <a href="https://www.linkedin.com/in/erdo%C4%9Fan-aydin-4b9909207/" style={{ textDecoration: "none" }}>EA</a>
                    </p>
                </footer>
            </div>
        );
    }
}

export default Footer;
