// import React, { Component } from 'react';
// import { Card, CardImg, CardBody, CardText, CardTitle } from 'reactstrap';
// class AboutComponent extends Component {
//     render() {
//         return (
//             <div>
//                 <Card className="my-2">
//                     <CardImg
//                         alt="Card image cap"
//                         src="https://picsum.photos/900/180"
//                         style={{
//                             height: 180
//                         }}
//                         top
//                         width="200px"
//                     />
//                     <CardBody>
//                         <CardTitle className = 'justify-content-center' tag="h5">
//                             Card Title
//                         </CardTitle>
//                         <CardText>
//                             This is a wider card with supporting texQt below as a natural lead-in to additional content. This content is a little bit longer.
//                         </CardText>
//                         <CardText>
//                             <small className="text-muted">
//                                 Last updated 3 mins ago
//                             </small>
//                         </CardText>
//                     </CardBody>
//                 </Card>

//             </div>
//         );
//     }
// }

// export default AboutComponent;
import React, { Component } from 'react';
import { Card, CardImg, CardBody, CardText, CardTitle } from 'reactstrap';
import image5 from "../images/img5.jpg"

class AboutComponent extends Component {
    render() {
        return (
            <div className="d-flex justify-content-center">
                <br />
                <br />
                <Card
                    className="my-2 text-center"
                    style={{
                        border: "5px solid #0e0546",
                        borderRadius: "10px",
                        width: "800px", // Kartın genişliğini ayarladık
                        boxShadow: "10px 10px 100px rgba(0, 0, 0, 1)" // Daha belirgin gölge ayarları
                    }}
                >
                    <br />
                    <CardImg
                        alt="Card image cap"
                        src={image5} // Kare boyut için fotoğrafı 1:1 oranında aldık
                        style={{
                            height: 300, // Küçük boyut için yüksekliği ayarladık
                            width: 300,
                            borderRadius: "50%", // Yuvarlak yapmak için
                            objectFit: "cover", // Fotoğrafı tam ortalayarak kırpma
                            margin: "0 auto", // Ortalamak için
                        }}
                        top
                    />
                    <CardBody>
                        <CardTitle className="d-flex justify-content-center" tag="h5">
                            HAKKIMIZDA
                        </CardTitle>
                        <CardText>
                            Flow kontrol sistemleri olarak, pompa sistemleri, pompa panoları ve mekanik bağlantı elemanları
                            alanında değerli müşterilerimize satış ve servis desteği sağlamak için yola çıktık. Kalite odaklı hizmet
                            anlayışımız ve 7/24 kesintisiz çalışma prensibimizle her marka ve tür pompa için müşterilerimizin yanındayız.
                            Müşteri memnuniyetini ön planda tutarak, sektörün gereksinimlerine uygun çözümler sunmayı amaçlıyoruz.
                            Deneyimli ekibimiz ve geniş ürün yelpazemizle, uzun ömürlü ve güvenilir çözümler sunarak sektörde fark yaratmayı
                            hedefliyoruz. Her geçen gün gelişen teknolojiye ayak uydurarak, müşteri ihtiyaçlarına en hızlı ve etkili şekilde yanıt
                            vermeye devam ediyoruz.
                        </CardText>
                        <CardText className="d-flex justify-content-center" tag="h6">
                            FLOW KONTROL SİSTEMLERİ
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default AboutComponent;

