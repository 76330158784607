import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import '../CSS/Navi.css';
import image from "../images/logo.jpg";

class Navi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <div style={{ marginBottom: this.state.isOpen ? '120px' : '0' }}>
                <Navbar dark expand="md" className="custom-navbar">
                    <Container className="d-flex justify-content-between align-items-center">
                        <NavbarBrand href="/" style={{ paddingLeft: '2rem' }} className='navbar-link'>
                            <img
                                src={image}
                                alt=""
                                style={{
                                    height: 78,
                                    width: 200
                                }}
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="custom-toggler ms-auto" />
                    </Container>
                    <Collapse isOpen={this.state.isOpen} navbar transitionDuration={10}> 
                        <Nav className="mx-auto flex-column flex-md-row" navbar>
                            <NavItem>
                                <NavLink href="/products" className='navbar-link'>Hizmetlerimiz</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/" className='navbar-link'>Referanslarımız</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/about" className='navbar-link'>Hakkımızda</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/contact" className='navbar-link'>İletişim</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Navi;
